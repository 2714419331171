@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap');

body {
    /*background-color: #f0f0f0;*/
    background-color: #fff;
    /*font-family: 'Inconsolata', monospace;*/
    font-family: "Source Serif Pro", serif;
    /*font-family: math;*/
}

* {
    color: #333333;
}

.title {
    font-size: 4rem;
    font-weight: 700;
    /*color: #25292E;*/
    color: #333333;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*height: 100vh;*/
    width: 100vw;
    @apply text-center;
}

.sub {
    /*font-family: 'Inconsolata', monospace;*/
}

.typer {
    /*font-family: 'Inconsolata', monospace;*/
}

.sep {
    height: 100vh;
}

::selection {
  /*background-color: #23272C;*/
  background-color: #29252E;
  color: #fff;
}

.vid-container {
    /*display: flex;*/
    /*flex: 1;*/
    /*flex-direction: column;*/
    /*height: fit-content;*/
    height: 50vw;
    @apply pr-14 pl-14;
}

.svid-container {
    /*display: flex;*/
    /*flex: 1;*/
    /*flex-direction: column;*/
    /*height: fit-content;*/
    height: 30vw;
    @apply pr-14 pl-14;
}

.mvid-container {
    /*display: flex;*/
    /*flex: 1;*/
    /*flex-direction: column;*/
    /*height: fit-content;*/
    height: 42vw;
    @apply pr-14 pl-14;
}


.desc {
    border-left: 8px solid lightgrey;
    @apply pt-2 pb-2 pl-4 m-12 text-lg;
}

.im {
    @apply flex justify-center;
}


.em-t {
    /*border: 1px solid red;*/
    width: 100%;
    @apply mt-12 mr-16 text-2xl font-black text-right;
}

.center-xy {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
